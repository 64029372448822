/* Global Styles */
* {
  box-sizing: border-box;
}

body, html {
  background-color: #F4F2EF;
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Specific Styles */
#mobile, #otp {
  width: 70% !important;
}
